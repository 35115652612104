import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AllRouteConstants } from '../../router/routes';
import LoginModal from './LoginModal';
import logo from '../../assets/images/truetrades.png';
import { CSSTransition } from 'react-transition-group';

import loginIcon from '../../assets/images/loginIcon.png';
import { useAppSelector } from '../../hooks/useAppSelector';

const PublicLayout: React.FC = () => {
	const navigate = useNavigate();

	const [isLoginOpen, setIsLoginOpen] = useState(false);

	const menuRef = useRef(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

	const [step, setStep] = useState<number | null>(null);
	const toggleStep = (step: number | null = null) => setStep(step);

	const getStepTiltle = () => {
		if (step === 1) {
			return 'Crypto';
		} else if (step === 2) {
			return 'Resources';
		} else if (step === 3) {
			return 'Legal';
		}

		return '';
	};

	useEffect(() => {
		if (!isMenuOpen) {
			setStep(null);
		}
	}, [isMenuOpen]);

	const cryptoRef = useRef(null);
	const resourcesRef = useRef(null);
	const legalRef = useRef(null);

	const [scrollPosition, setScrollPosition] = useState(0);

	const isHeaderOpaque = useMemo(() => {
		return scrollPosition >= 100;
	}, [scrollPosition]);

	const handleScroll = () => {
		const scrollPosition = window.scrollY; // => scroll position
		console.log(scrollPosition);
		setScrollPosition(scrollPosition);
	};

	useEffect(() => {
		handleScroll();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const { tokens, user_data } = useAppSelector((state) => state.authReducer);

	return (
		<>
			{isLoginOpen && (
				<LoginModal
					isOpen={isLoginOpen}
					onClose={() => setIsLoginOpen(false)}
				/>
			)}

			<div className='bv-public-layout'>
				<a onClick={() => {}} className='bv-public-layout__feedback-button'>
					Live Chat
				</a>

				<div className='tradingview-widget-container'>
					<iframe
						scrolling='no'
						src='https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22Nasdaq%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22BTC%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22ETH%2FUSD%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22truetrades.us%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22truetrades.us%2F%23%22%7D'
					/>
				</div>

				<header
					className={`bv-public-layout__header bv-public-layout__header--menu-right landing-1-menu bv-public-layout__header--absolute bv-public-layout__header--sticky ${
						isHeaderOpaque ? 'opaque' : undefined
					}`}
				>
					<div className='container desktop p-0'>
						<nav className={'navbar site-navbar'}>
							<div className='brand-logo'>
								<a href='#'>
									<img src={logo} alt='' className='dark-version-logo' />
								</a>
							</div>

							<div className='menu-block-wrapper'>
								<div className='menu-overlay'></div>
								<nav className='menu-block' id='append-menu-header'>
									<ul className='site-menu-main'>
										<li className='nav-item nav-item-has-children'>
											<a
												onClick={() => navigate(AllRouteConstants.main.index)}
												className='nav-link-item drop-trigger'
											>
												Home <i className='fas fa-univeristy'></i>
											</a>
										</li>
										<li className='nav-item'>
											<a
												onClick={() =>
													navigate(AllRouteConstants.main.about_us)
												}
												className='nav-link-item'
											>
												About
											</a>
										</li>

										<li className='nav-item'>
											<a
												onClick={() =>
													navigate(AllRouteConstants.main.trading_plans)
												}
												className='nav-link-item'
											>
												Trading Plans
											</a>
										</li>

										<li className='nav-item nav-item-has-children'>
											<a href='#' className='nav-link-item drop-trigger'>
												Crypto{' '}
												<FontAwesomeIcon
													className='icon'
													icon={icon({ name: 'angle-down', style: 'solid' })}
												/>
											</a>
											<ul className='sub-menu' id='submenu-10'>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(AllRouteConstants.main.bitcoin_trading)
														}
													>
														Bitcoin Trading
													</a>
												</li>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(
																AllRouteConstants.main.bitcoin_cash_trading
															)
														}
													>
														Bitcoin Cash Trading
													</a>
												</li>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(AllRouteConstants.main.litecoin_trading)
														}
													>
														Litecoin Trading
													</a>
												</li>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(AllRouteConstants.main.etherum_trading)
														}
													>
														Etherum Trading
													</a>
												</li>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(AllRouteConstants.main.ripple_trading)
														}
													>
														Ripple Trading
													</a>
												</li>
											</ul>
										</li>

										<li className='nav-item nav-item-has-children'>
											<a href='#' className='nav-link-item drop-trigger'>
												Resources{' '}
												<FontAwesomeIcon
													className='icon'
													icon={icon({ name: 'angle-down', style: 'solid' })}
												/>
											</a>
											<ul className='sub-menu' id='submenu-11'>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(
																AllRouteConstants.main.financial_instruments
															)
														}
													>
														Financial Instruments
													</a>
												</li>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(AllRouteConstants.main.live_payouts)
														}
													>
														Live Payouts
													</a>
												</li>

												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(AllRouteConstants.main.risk_disclosure)
														}
													>
														Risk Disclosure
													</a>
												</li>
											</ul>
										</li>

										<li className='nav-item nav-item-has-children'>
											<a href='#' className='nav-link-item drop-trigger'>
												Legal{' '}
												<FontAwesomeIcon
													className='icon'
													icon={icon({ name: 'angle-down', style: 'solid' })}
												/>
											</a>
											<ul className='sub-menu' id='submenu-11'>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(AllRouteConstants.main.terms_of_use)
														}
													>
														Terms Of use
													</a>
												</li>
												<li className='sub-menu--item'>
													<a
														onClick={() =>
															navigate(AllRouteConstants.main.privacy_policy)
														}
													>
														Privacy Policy
													</a>
												</li>
											</ul>
										</li>

										<li>
											<a
												onClick={() =>
													navigate(AllRouteConstants.main.contact_us)
												}
												className='nav-link-item'
											>
												Contact
											</a>
										</li>
										<li className='nav-link-item'>
											<button
												type='button'
												className='sign-btn'
												data-bs-toggle='modal'
												data-bs-target='#exampleModal'
												data-bs-whatever='@mdo'
												onClick={() => {
													if (user_data && tokens) {
														navigate(AllRouteConstants.user.home);
													} else {
														setIsLoginOpen(true);
													}
												}}
											>
												{(!user_data || !tokens) && (
													<img src={loginIcon} alt='' />
												)}{' '}
												{user_data && tokens ? 'Dashboard' : 'Login'}
											</button>
										</li>
									</ul>
								</nav>
							</div>
							<div className='mobile-menu-trigger'>
								<span></span>
							</div>
						</nav>
					</div>

					<div className='conatiner mobile'>
						<div className='brand-logo'>
							<Link to={AllRouteConstants.user.home}>
								<img src={logo} alt='' className='dark-version-logo' />
							</Link>
						</div>

						<div className='mobile-menu-trigger' onClick={toggleMenu}>
							<span></span>
						</div>

						<CSSTransition
							nodeRef={menuRef}
							in={isMenuOpen}
							classNames={'nav-menu'}
							unmountOnExit
							timeout={500}
						>
							<div className='nav-menu' ref={menuRef}>
								<div className='nav-menu__head'>
									<div
										className='go-back'
										onClick={step ? () => toggleStep() : undefined}
									>
										{step && (
											<FontAwesomeIcon
												icon={icon({ name: 'chevron-left', style: 'solid' })}
												className='icon'
											/>
										)}
									</div>

									<div className='title'>{getStepTiltle()}</div>

									<div className='close' onClick={toggleMenu}>
										×
									</div>
								</div>

								<div className='nav-menu__content'>
									<CSSTransition
										unmountOnExit
										nodeRef={cryptoRef}
										in={step === 1}
										classNames={'extra'}
										timeout={500}
									>
										<ul ref={cryptoRef} className='extra'>
											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.bitcoin_trading}
												>
													Bitcoin Trading
												</Link>
											</li>

											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.bitcoin_cash_trading}
												>
													Bitcoin Cash Trading
												</Link>
											</li>

											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.litecoin_trading}
												>
													Litecoin Trading
												</Link>
											</li>

											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.etherum_trading}
												>
													Etherum Trading
												</Link>
											</li>

											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.ripple_trading}
												>
													Ripple Trading
												</Link>
											</li>
										</ul>
									</CSSTransition>

									<CSSTransition
										unmountOnExit
										nodeRef={resourcesRef}
										in={step === 2}
										classNames={'extra'}
										timeout={500}
									>
										<ul ref={resourcesRef} className='extra'>
											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.financial_instruments}
												>
													Financial Instruments
												</Link>
											</li>

											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.live_payouts}
												>
													Live Payouts
												</Link>
											</li>

											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.risk_disclosure}
												>
													Risk Disclosure
												</Link>
											</li>
										</ul>
									</CSSTransition>

									<CSSTransition
										unmountOnExit
										nodeRef={legalRef}
										in={step === 3}
										classNames={'extra'}
										timeout={500}
									>
										<ul ref={legalRef} className='extra'>
											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.terms_of_use}
												>
													Terms of Use
												</Link>
											</li>

											<li onClick={toggleMenu}>
												<Link
													className='nav-link-item'
													to={AllRouteConstants.main.privacy_policy}
												>
													Privacy Policy
												</Link>
											</li>
										</ul>
									</CSSTransition>

									<ul>
										<li onClick={toggleMenu}>
											<Link
												className='nav-link-item'
												to={AllRouteConstants.main.index}
											>
												Home
											</Link>
										</li>

										<li onClick={toggleMenu}>
											<Link
												className='nav-link-item'
												to={AllRouteConstants.main.about_us}
											>
												About
											</Link>
										</li>

										<li onClick={toggleMenu}>
											<Link
												className='nav-link-item'
												to={AllRouteConstants.main.trading_plans}
											>
												Trading Plans
											</Link>
										</li>

										<li className='has-children'>
											<div
												className='nav-link-item'
												onClick={() => toggleStep(1)}
											>
												<span>Crypto</span>

												<FontAwesomeIcon
													className='icon'
													icon={icon({
														name: 'chevron-right',
														style: 'solid',
													})}
												/>
											</div>
										</li>

										<li className='has-children'>
											<div
												className='nav-link-item'
												onClick={() => toggleStep(2)}
											>
												<span>Resources</span>

												<FontAwesomeIcon
													className='icon'
													icon={icon({
														name: 'chevron-right',
														style: 'solid',
													})}
												/>
											</div>
										</li>

										<li className='has-children'>
											<div
												className='nav-link-item'
												onClick={() => toggleStep(3)}
											>
												<span>Legal</span>

												<FontAwesomeIcon
													className='icon'
													icon={icon({
														name: 'chevron-right',
														style: 'solid',
													})}
												/>
											</div>
										</li>

										<li onClick={toggleMenu}>
											<Link
												className='nav-link-item'
												to={AllRouteConstants.main.contact_us}
											>
												Contact
											</Link>
										</li>

										<li>
											<div className='nav-link-item' onClick={toggleMenu}>
												<button
													type='button'
													className='sign-btn'
													onClick={() => {
														if (user_data && tokens) {
															navigate(AllRouteConstants.user.home);
														} else {
															setIsLoginOpen(true);
														}
													}}
												>
													{(!user_data || !tokens) && (
														<img
															src={loginIcon}
															alt=''
															style={{ marginRight: 8 }}
														/>
													)}{' '}
													{user_data && tokens ? 'Dashboard' : 'Login'}
												</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</CSSTransition>
					</div>
				</header>

				<Outlet />

				<div className='footer-contact-area bg-img bg-overlay  bg-fixed section-padding-100'>
					<div className='container'>
						<div className='row justify-content-center'>
							<div className='col-lg-12'>
								<p className='risk-disclosure__title'>Risk Warning</p>
								<p>
									<small>
										Trading on financial markets carries risks. Forex and
										financial contracts are a complex financial product that are
										traded on margin. Trading carries a high level of risk since
										leverage can work both to your advantage and disadvantage.
										As a result, this type of activity may not be suitable for
										all investors because you may lose all your invested
										capital. You should not risk more than you are prepared to
										lose. Before deciding to trade, you need to ensure that you
										understand the risks involved and taking into account your
										investment objectives and level of experience
									</small>
								</p>
								<p className='risk-disclosure__link-title'>
									Full document:{' '}
									<a
										href='https://truetrades.us/risk-disclosure'
										className='risk-disclosure__link'
									>
										Risk Disclosure{' '}
									</a>
								</p>
							</div>
						</div>
					</div>

					<div className='container'>
						<div className='row justify-content-center'>
							<div className='col-sm-5 col-lg-4'>
								<div className='footer-single-widget mb-50'>
									<div className='footer-logo'>
										<a href='#'>
											<img src={logo} alt='logo' />
										</a>
									</div>
									<div className='f-add-info mt-30'>
										<p>
											Phone - <a href='#'>+1 (804) 332-1087</a>
										</p>
										<p>
											E-mail - <a href='#'>support@truetrades.us</a>
										</p>
									</div>
								</div>
							</div>

							<div className='col-sm-7 col-lg-8'>
								<div className='row'>
									<div className='col-sm-6 col-lg-3'>
										<div className='footer-single-widget first mb-50'>
											<h4>Quick links</h4>
											<ul>
												<li>
													<Link to={AllRouteConstants.main.index}>Home</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.about_us}>
														About
													</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.trading_plans}>
														Trading Plans
													</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.contact_us}>
														Contact Us
													</Link>
												</li>
											</ul>
										</div>
									</div>

									<div className='col-sm-6 col-lg-3'>
										<div className='footer-single-widget second mb-50'>
											<h4>Crypto</h4>
											<ul>
												<li>
													<Link to={AllRouteConstants.main.bitcoin_trading}>
														Bitcoin Trading
													</Link>
												</li>
												<li>
													<Link
														to={AllRouteConstants.main.bitcoin_cash_trading}
													>
														Bitcoin Cash Trading
													</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.etherum_trading}>
														Etherum Trading
													</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.litecoin_trading}>
														Litecoin Trading
													</Link>
												</li>
											</ul>
										</div>
									</div>

									<div className='col-sm-6 col-lg-3'>
										<div className='footer-single-widget third mb-50'>
											<h4>Resources</h4>
											<ul>
												<li>
													<Link
														to={AllRouteConstants.main.financial_instruments}
													>
														Financial Instruments
													</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.live_payouts}>
														Live Payouts
													</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.risk_disclosure}>
														Risk Disclosure
													</Link>
												</li>
											</ul>
										</div>
									</div>

									<div className='col-sm-6 col-lg-3'>
										<div className='footer-single-widget four mb-50'>
											<h4>Legal</h4>
											<ul>
												<li>
													<Link to={AllRouteConstants.main.privacy_policy}>
														Privacy Policy
													</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.terms_of_use}>
														Terms Of Use
													</Link>
												</li>

												<li>
													<Link to={AllRouteConstants.main.aml_policy}>
														AML Policy{' '}
													</Link>
												</li>
												<li>
													<Link to={AllRouteConstants.main.payment_policy}>
														Payment Policy
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='copy-right-area'>
						<div className='container'>
							<div className='row'>
								<div className='col-md-6'>
									<div className='copy-right-content-text'>
										<p>© 2023 All Rights Reserved.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PublicLayout;
