import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { RequireAuth } from '../../HoC/RequireAuth';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import {
	useAuthActions,
	useCachedActions,
	useRedirectActions,
	useScreenActions,
} from '../../hooks/useReduxActions';
import PopMenuModal from './MenuModal';
import { PopMenuItem } from '../../interfaces/others';
import { AllRouteConstants } from '../../router/routes';
import logo from '../../assets/images/truetrades.png';
import MobileFooter from './MobileFooter';
import { CSSTransition } from 'react-transition-group';
import AnimateHeight from 'react-animate-height';
import { useAppSelector } from '../../hooks/useAppSelector';
import SuccessModal from '../../components/SuccessModal';
import { BvTokens } from '../../interfaces/Auth';
import authService from '../../services/AuthService';
import useApi from '../../hooks/useApi';
import { toast } from 'react-toastify';
import defaultImg from '../../assets/images/default.jpeg';

const tradingOptions: PopMenuItem[] = [
	{
		title: 'Quick Trading',
		imgSrc:
			'https://img.icons8.com/external-wanicon-flat-wanicon/24/000000/external-trading-stock-market-wanicon-flat-wanicon.png',
		path: AllRouteConstants.user.home,
	},
	{
		title: 'Trade History',
		imgSrc:
			'https://img.icons8.com/external-filled-outline-satawat-anukul/24/000000/external-trading-trading-filled-outline-style-filled-outline-satawat-anukul-69.png',
		path: AllRouteConstants.user.trade_history,
	},
];

const depositOptions: PopMenuItem[] = [
	{
		title: 'Deposit Fund',
		imgSrc: 'https://img.icons8.com/color/24/000000/money-bag.png',
		path: AllRouteConstants.user.deposit_fund,
	},
	{
		title: 'Deposit History',
		imgSrc:
			'https://img.icons8.com/external-itim2101-flat-itim2101/24/000000/external-deposit-money-and-economy-itim2101-flat-itim2101-1.png',
		path: AllRouteConstants.user.deposit_history,
	},
];

const withdrawOptions: PopMenuItem[] = [
	{
		title: 'Withdraw Fund',
		imgSrc: 'https://img.icons8.com/fluency/24/000000/money-circulation.png',
		path: AllRouteConstants.user.withdraw_fund,
	},
	{
		title: 'Withdrawal History',
		imgSrc: 'https://img.icons8.com/fluency/24/000000/order-history.png',
		path: AllRouteConstants.user.withdraw_history,
	},
];

const profileOptions: PopMenuItem[] = [
	{
		title: 'Edit Profile',
		imgSrc:
			'https://img.icons8.com/ios-filled/24/000000/edit-administrator.png',
		path: AllRouteConstants.user.edit_profile,
	},
	{
		title: 'Change Password',
		imgSrc: 'https://img.icons8.com/doodle/24/000000/security-checked--v1.png',
		path: AllRouteConstants.user.change_password,
	},
	{
		title: 'Kyc Verification',
		imgSrc: 'https://img.icons8.com/fluency/24/000000/security-checked.png',
		path: AllRouteConstants.user.verify,
	},
];

const get_email_verif_link = (tokens: BvTokens) =>
	authService.get_email_verification_link(tokens);

const UserLayout: React.FC = () => {
	const navigate = useNavigate();

	const [isAccInfoOpen, setIsAccInfoOpen] = useState(false);
	const accInfoRef = useRef(null);
	useOutsideAlerter(accInfoRef, () => setIsAccInfoOpen(false));

	const { signOut } = useAuthActions();

	const [isPopMenuOpen, setIsPopMenuOpen] = useState(false);

	const [popMenuData, setPopMenuData] = useState<{
		title: string;
		options: PopMenuItem[];
	} | null>(null);

	const openPopMenu = (title: string, options: PopMenuItem[]) => {
		setPopMenuData({ title, options });
		setIsPopMenuOpen(true);
	};

	const closePopMenu = () => {
		setPopMenuData(null);
		setIsPopMenuOpen(false);
	};

	const mobileMenuRef = useRef(null);
	const sidebarRef = useRef(null);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const openMobileMenu = () => {
		setIsMobileMenuOpen(true);
	};

	const closeMobileMenu = () => {
		setIsMobileMenuOpen(false);
	};

	const [currentSubMenu, setCurrentSubMenu] = useState<number | null>(null);
	const toggleCurrentSubMenu = (input: number) => () => {
		if (input === currentSubMenu) {
			setCurrentSubMenu(null);
		} else {
			setCurrentSubMenu(input);
		}
	};

	useEffect(() => {
		if (!isMobileMenuOpen) {
			setCurrentSubMenu(null);
		}
	}, [isMobileMenuOpen]);

	const { successAlert } = useAppSelector((state) => state.screenStateReducer);
	const { user_data, tokens } = useAppSelector((state) => state.authReducer);

	const { toggleSuccessAlert } = useScreenActions();

	useEffect(() => {
		return () => {
			toggleSuccessAlert();
		};
	}, []);

	const { mode } = useAppSelector((state) => state.cachedReducer);
	const { toggleTradeMode, setTradeMode } = useCachedActions();

	const getEmailVerifLinkApiRequest = useApi<
		{ status: boolean; message: string },
		BvTokens
	>(get_email_verif_link);

	useEffect(() => {
		if (getEmailVerifLinkApiRequest.error) {
			toast(getEmailVerifLinkApiRequest.error, {
				theme: 'colored',
				type: 'error',
			});
		}
	}, [getEmailVerifLinkApiRequest.error]);

	const sendEmailVerifLink = async () => {
		if (tokens) {
			getEmailVerifLinkApiRequest.request(tokens).then((data) => {
				if (data) {
					toast(data.message, { theme: 'colored', type: 'success' });
				}
			});
		}
	};

	const { auth_redirect_route } = useAppSelector(
		(state) => state.redirectReducer
	);
	const { setAuthRedirect } = useRedirectActions();

	useEffect(() => {
		if (auth_redirect_route) {
			navigate(auth_redirect_route);
			setAuthRedirect();
		}
	}, [auth_redirect_route]);

	return (
		<RequireAuth>
			<>
				{isPopMenuOpen && popMenuData && (
					<PopMenuModal
						isOpen={isPopMenuOpen}
						onClose={closePopMenu}
						data={popMenuData}
					/>
				)}

				{successAlert && (
					<SuccessModal
						handleOk={toggleSuccessAlert}
						open={successAlert !== null}
						close={toggleSuccessAlert}
						text={successAlert}
					/>
				)}

				<CSSTransition
					nodeRef={mobileMenuRef}
					in={isMobileMenuOpen}
					classNames={'mobile-menu-nav'}
					unmountOnExit
					timeout={500}
				>
					<div
						ref={mobileMenuRef}
						className='mobile-menu-nav'
						onClick={closeMobileMenu}
					>
						<CSSTransition
							nodeRef={sidebarRef}
							in={isMobileMenuOpen}
							classNames={'mobile-menu-nav__sidebar'}
							unmountOnExit
							timeout={500}
						>
							<nav
								ref={sidebarRef}
								className='mobile-menu-nav__sidebar'
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<div
									className='go-back-btn'
									id='goBack'
									onClick={closeMobileMenu}
								>
									<svg
										className='bi bi-x'
										width='24'
										height='24'
										viewBox='0 0 16 16'
										fill='currentColor'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fill-rule='evenodd'
											d='M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z'
										></path>
										<path
											fill-rule='evenodd'
											d='M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z'
										></path>
									</svg>
								</div>

								<div className='sidenav-profile'>
									<div className='sidenav-style1'></div>

									<div className='user-info'>
										<div className='user-profile'>
											<img
												src={
													user_data?.avatar
														? encodeURI(user_data.avatar)
														: defaultImg
												}
												alt='avatar'
											/>
										</div>

										<h6
											className='user-name mb-0'
											style={{
												color: '#fff',
											}}
										>
											{user_data?.email || ''}
										</h6>

										{user_data && !user_data.email_verified && (
											<h6 className=''>
												<span>
													<i>
														(
														{user_data?.email_verified
															? 'Verified'
															: 'Not Verified'}
														)
													</i>
												</span>
												<button
													className='btn d-inline ms-2 btn-autowidth btn-small btn-grey'
													disabled={getEmailVerifLinkApiRequest.loading}
													onClick={() => {
														closeMobileMenu();
														sendEmailVerifLink();
													}}
												>
													Send Link
												</button>
											</h6>
										)}

										<br />

										<div className='btn btn-warning m-1'>
											<span className='ms-1 badge rounded-pill bg-dark'>
												Bal: ${user_data?.balance || 0}
											</span>
										</div>

										<br />

										<div className='btn btn-dark m-1'>
											<span className='ms-1 badge bg-primary'>
												Demo Bal: ${user_data?.demo_balance || 0}
											</span>
										</div>
									</div>
								</div>

								<ul className='sidenav-nav ps-0'>
									<li
										className='affan-dropdown-menu'
										onClick={toggleCurrentSubMenu(1)}
									>
										<a href='#'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20'
												height='20'
												fill='currentColor'
												className='bi bi-graph-up'
												viewBox='0 0 16 16'
											>
												<path
													fill-rule='evenodd'
													d='M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z'
												></path>
											</svg>
											Trading
										</a>

										<AnimateHeight
											duration={300}
											height={currentSubMenu === 1 ? 'auto' : 0}
										>
											<ul>
												{mode === 'demo' && (
													<li onClick={closeMobileMenu}>
														<Link
															to={AllRouteConstants.user.home}
															onClick={() => setTradeMode('real')}
														>
															Live Trading
														</Link>
													</li>
												)}
												{mode === 'real' && (
													<li onClick={closeMobileMenu}>
														<Link
															to={AllRouteConstants.user.home}
															onClick={() => setTradeMode('demo')}
														>
															Trade Demo
														</Link>
													</li>
												)}
												<li onClick={closeMobileMenu}>
													<Link to={AllRouteConstants.user.trade_history}>
														Trading History
													</Link>
												</li>
											</ul>
										</AnimateHeight>

										<div
											className={`dropdown-trigger-btn ${
												currentSubMenu === 1 ? 'active' : undefined
											}`}
										>
											<FontAwesomeIcon
												className='icon'
												size='sm'
												icon={icon({ name: 'angle-right', style: 'solid' })}
											/>
										</div>
									</li>

									{mode === 'real' && (
										<li
											className='affan-dropdown-menu'
											onClick={toggleCurrentSubMenu(2)}
										>
											<a href='#'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='20'
													height='20'
													fill='currentColor'
													className='bi bi-arrow-left-right'
													viewBox='0 0 16 16'
												>
													<path
														fill-rule='evenodd'
														d='M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z'
													></path>
												</svg>
												Finance
											</a>

											<AnimateHeight
												duration={300}
												height={currentSubMenu === 2 ? 'auto' : 0}
											>
												<ul>
													<li onClick={closeMobileMenu}>
														<Link to={AllRouteConstants.user.deposit_fund}>
															Deposit
														</Link>
													</li>
													<li onClick={closeMobileMenu}>
														<Link to={AllRouteConstants.user.withdraw_fund}>
															Withdrawal
														</Link>
													</li>
													<li onClick={closeMobileMenu}>
														<Link to={AllRouteConstants.user.deposit_history}>
															Deposit History
														</Link>
													</li>
													<li onClick={closeMobileMenu}>
														<Link to={AllRouteConstants.user.withdraw_history}>
															Withdrawal History
														</Link>
													</li>
													<li onClick={closeMobileMenu}>
														<Link to={AllRouteConstants.user.profit}>
															Profits
														</Link>
													</li>
												</ul>
											</AnimateHeight>

											<div
												className={`dropdown-trigger-btn ${
													currentSubMenu === 2 ? 'active' : undefined
												}`}
											>
												<FontAwesomeIcon
													className='icon'
													size='sm'
													icon={icon({ name: 'angle-right', style: 'solid' })}
												/>
											</div>
										</li>
									)}

									{mode === 'real' && (
										<li onClick={closeMobileMenu}>
											<Link to={AllRouteConstants.user.plan}>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='20'
													height='20'
													fill='currentColor'
													className='bi bi-card-list'
													viewBox='0 0 16 16'
												>
													<path d='M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z'></path>
													<path d='M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z'></path>
												</svg>
												<span>Trading Plans</span>
											</Link>
										</li>
									)}

									<li
										className='affan-dropdown-menu'
										onClick={toggleCurrentSubMenu(3)}
									>
										<a>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20'
												height='20'
												fill='currentColor'
												className='bi bi-person-fill'
												viewBox='0 0 16 16'
											>
												<path d='M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z'></path>
											</svg>
											Profile
										</a>

										<AnimateHeight
											duration={300}
											height={currentSubMenu === 3 ? 'auto' : 0}
										>
											<ul>
												<li onClick={closeMobileMenu}>
													<Link to={AllRouteConstants.user.edit_profile}>
														Account Profile
													</Link>
												</li>
												<li onClick={closeMobileMenu}>
													<Link to={AllRouteConstants.user.verify}>KYC</Link>
												</li>
											</ul>
										</AnimateHeight>

										<div
											className={`dropdown-trigger-btn ${
												currentSubMenu === 3 ? 'active' : undefined
											}`}
										>
											<FontAwesomeIcon
												className='icon'
												size='sm'
												icon={icon({ name: 'angle-right', style: 'solid' })}
											/>
										</div>
									</li>

									<li onClick={closeMobileMenu}>
										<a onClick={signOut}>
											<svg
												width='20'
												height='20'
												viewBox='0 0 16 16'
												className='bi bi-box-arrow-right'
												fill='currentColor'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													fill-rule='evenodd'
													d='M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z'
												></path>
												<path
													fill-rule='evenodd'
													d='M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z'
												></path>
											</svg>
											Logout
										</a>
									</li>
								</ul>
							</nav>
						</CSSTransition>
					</div>
				</CSSTransition>

				<div className='bv-user-layout'>
					<header className='desktop'>
						<div>
							<div>
								<div className='kr-logo'>
									<img src={logo} height='70' alt='' />
								</div>
							</div>

							<div>
								<div
									className={`kr-heeader-btn kr-heeader-btn-identity ${mode}`}
								>
									<button
										className={`btn btn-big btn-green btn-autowidth ${mode}`}
										onClick={toggleTradeMode}
									>
										{mode === 'real' ? 'Trade Demo' : 'Trade Live'}
									</button>
								</div>

								<div className='kr-wallet-top' ref={accInfoRef}>
									<div
										className={`kr-wallet-top-${mode}`}
										onClick={() => setIsAccInfoOpen(true)}
									>
										<div>
											<span>
												{mode === 'demo' ? 'Demo Account' : 'Real Account'}
											</span>
											<span
												kr-balance-id='RUYveVBmSGZDMGJqVU5WcXZSUHd4Zz09'
												className='kr-wallet-top-ammount'
											>
												<i>
													{mode === 'real'
														? user_data?.balance || 0
														: user_data?.demo_balance || 0}
												</i>{' '}
												$
											</span>
											<label>Estimate balance</label>
										</div>

										<FontAwesomeIcon
											icon={icon({ name: 'chevron-down', style: 'solid' })}
										/>
									</div>

									{isAccInfoOpen && (
										<section>
											<div className='kr-wallet-top-resum'>
												<h3>Account Info</h3>
												<ul>
													<li kr-wallet-symbol='USDT'>
														<span>Email:</span>
														<div></div>
														<span>
															<i>
																{user_data?.email || ''} (
																{user_data?.email_verified
																	? 'Verified'
																	: 'Not Verified'}
																)
															</i>
														</span>
													</li>

													{user_data && !user_data.email_verified && (
														<li>
															<button
																className='btn btn-autowidth btn-small btn-grey'
																disabled={getEmailVerifLinkApiRequest.loading}
																onClick={sendEmailVerifLink}
															>
																Send Link
															</button>
														</li>
													)}

													<li kr-wallet-symbol='ETH'>
														<span>Current Trading Plan</span>
														<div></div>
														<span>
															{user_data?.trading_plan.split('-')[0] || 'None'}
														</span>
													</li>

													<li kr-wallet-symbol='UNI'>
														<span>Trading Type</span>
														<div></div>
														<span>
															{mode === 'real'
																? 'Live Trading'
																: 'Demo Trading'}
														</span>
													</li>
												</ul>
												<div className='kr-wallet-balance-show-list kr-wallet-balance-show-list-native'>
													<a
														onClick={signOut}
														className='btn btn-autowidth btn-green btn-small'
													>
														Logout
													</a>
												</div>
											</div>

											<div className='kr-wallet-top-change'>
												<h3>
													<span>Account Balance</span>
												</h3>

												<ul>
													<li
														kr-wallet-change='ek8wTktiT0VicUw5UzdMRjhjajBKQT09'
														className='kr-wallet-top-change-real'
													>
														<div>
															<div>
																<span>USD Balance</span>
																<label kr-balance-id='ek8wTktiT0VicUw5UzdMRjhjajBKQT09'>
																	$ <i> {user_data?.balance || 0} </i>
																</label>
															</div>
														</div>

														<section></section>
													</li>

													<li
														kr-wallet-change='ek8wTktiT0VicUw5UzdMRjhjajBKQT09'
														className='kr-wallet-top-change-real'
													>
														<div>
															<div>
																<span>USD Demo Balance</span>
																<label kr-balance-id='ek8wTktiT0VicUw5UzdMRjhjajBKQT09'>
																	$ <i> {user_data?.demo_balance || 0} </i>
																</label>
															</div>
														</div>
														<section></section>
													</li>
												</ul>
											</div>
										</section>
									)}
								</div>
							</div>
						</div>
					</header>

					<header className='mobile'>
						<div className='container'>
							<div className='header-content position-relative d-flex align-items-center justify-content-between'>
								<div className='logo-wrapper'>
									<Link to={AllRouteConstants.user.home}>
										<img src={logo} alt='' />
									</Link>
								</div>

								<div className='navbar-toggler' onClick={openMobileMenu}>
									<span className='d-block'></span>
									<span className='d-block'></span>
									<span className='d-block'></span>
								</div>
							</div>
						</div>
					</header>

					<section className='kr-page-content'>
						<nav className='kr-leftnav'>
							<ul>
								<li>
									<a onClick={() => openPopMenu('Trading', tradingOptions)}>
										<FontAwesomeIcon
											className='icon'
											icon={icon({ name: 'chart-line' })}
										/>
										<span>Trading</span>
									</a>
								</li>

								{mode === 'real' && (
									<li>
										<a onClick={() => openPopMenu('Deposit', depositOptions)}>
											<FontAwesomeIcon
												className='icon'
												icon={icon({ name: 'money-bill-transfer' })}
											/>
											<span>Deposit</span>
										</a>
									</li>
								)}

								{mode === 'real' && (
									<li>
										<a
											onClick={() => openPopMenu('Withdrawal', withdrawOptions)}
										>
											<FontAwesomeIcon
												className='icon'
												icon={icon({ name: 'sack-dollar' })}
											/>
											<span>Withdrawal</span>
										</a>
									</li>
								)}

								{mode === 'real' && (
									<li>
										<a onClick={() => navigate(AllRouteConstants.user.profit)}>
											<FontAwesomeIcon
												className='icon'
												icon={icon({ name: 'chart-simple' })}
											/>
											<span>Profits</span>
										</a>
									</li>
								)}

								{mode === 'real' && (
									<li>
										<a onClick={() => navigate(AllRouteConstants.user.plan)}>
											<FontAwesomeIcon
												className='icon'
												icon={icon({ name: 'sheet-plastic' })}
											/>
											<span>Plans</span>
										</a>
									</li>
								)}

								<li>
									<a
										onClick={() =>
											openPopMenu('Profile Settings', profileOptions)
										}
									>
										<FontAwesomeIcon
											className='icon'
											icon={icon({ name: 'user' })}
										/>
										<span>Profile</span>
									</a>
								</li>

								<li>
									<a onClick={signOut}>
										<FontAwesomeIcon
											className='icon logout'
											icon={icon({ name: 'right-from-bracket' })}
										/>
										<span>Logout </span>
									</a>
								</li>
							</ul>
						</nav>

						<Outlet />
					</section>

					<MobileFooter />
				</div>
			</>
		</RequireAuth>
	);
};

export default UserLayout;
